import React from "react"
import { motion } from "framer-motion"
import { Link } from "gatsby"

const variants = {
  hidden: { opacity: 0, x: -200, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: -100 },
}

export default function Hero({ news }) {
  const { post_id, slug, category, title, preview } = news[0].frontmatter

  return (
    <header className="hero">
      <motion.div
        initial="hidden"
        animate="enter"
        exit="exit"
        variants={variants}
        transition={{ type: "linear" }}
        className="wrapper"
      >
        <div className="news">
          <Link
            to={`/${category}`}
            className={`category ${
              category === "barca" ? "category-barca" : "category-other"
            }`}
          >
            #&nbsp;{category}
          </Link>
          <h2 className="news-title">{title}</h2>
          <p className="news-desc">{preview}</p>
          <Link
            to={`/${post_id}-${slug}`}
            className={`read-more ${
              category === "barca" ? "read-more-barca" : "read-more-other"
            }`}
          >
            Czytaj więcej
          </Link>
        </div>
      </motion.div>
    </header>
  )
}
