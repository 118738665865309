import React from "react"
import { motion } from "framer-motion"
import loadable from "@loadable/component"

const variants = {
  hidden: { opacity: 0, x: -200, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: -100 },
}

const SinglePost = loadable(() => import("./SinglePost"))

export default function PostsList({ posts, header }) {
  return (
    <motion.section
      initial="hidden"
      animate="enter"
      exit="exit"
      variants={variants}
      transition={{ type: "linear" }}
      className="posts"
    >
      <div className="wrapper">
        <h3 className="posts-header">{header ? header : "Ostatnie wpisy"}</h3>
        <div className="posts-list">
          {posts.map(post => {
            return <SinglePost key={post.id} {...post} />
          })}
        </div>
      </div>
    </motion.section>
  )
}
