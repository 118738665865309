import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Hero from "../components/Hero"
import PostsList from "../components/PostsList"
import SEO from "../components/SEO"

export default function Home({ data }) {
  const {
    hero: { nodes: news },
  } = data

  const {
    posts: { nodes: posts },
  } = data

  return (
    <Layout>
      <SEO
        title="Strona główna"
        description="Blog poświęcony w głównej mierze FC Barcelonie i komentowaniu bieżących wydarzeń mających miejsce w najlepszym klubie na świecie. Uzupełnieniem bloga będą treści związane z piłką nożną ogólnie, a także innymi sportami (skoki narciarskie!). Od czasu do czasu autor może mieć ochotę na podzielenie się spostrzeżeniami na temat życia, sytuacji w kraju itp."
      />
      <Hero news={news} />
      <PostsList posts={posts} />
    </Layout>
  )
}

export const query = graphql`
  {
    hero: allMdx(sort: { fields: frontmatter___date, order: DESC }, limit: 1) {
      nodes {
        frontmatter {
          slug
          post_id
          category
          preview
          title
        }
      }
    }

    posts: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 4
      skip: 1
    ) {
      nodes {
        frontmatter {
          category
          post_id
          slug
          preview
          title
        }
        id
      }
    }
  }
`
